<div class="navbar bg-base-100">
  <div class="navbar-start">
    <div class="dropdown">
      <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </div>
    </div>
    <a href="/" class="btn btn-ghost text-xl normal-case">SIKR</a>
  </div>

  <div class="navbar-end">
    <button type="button" (click)="toggleShowUserMenu()" id="colorCircle" class="user-icon"> </button>
    <div *ngIf="showUserMenu" id="user-menu" class="dropdown-menu">
      <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        <li><a (click)="logout()">Log ud</a></li>
      </ul>
    </div>


  </div>
</div>

<div class="p-4">
  <div *ngIf="!isRoot" class="text-lg breadcrumbs">
    <ul>
      <li>
        <a (click)="goBack()" tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" /></svg>
          <p class="ml-3">Tilbage</p>
        </a>
      </li>
    </ul>
  </div>
  <!--
  <div *ngIf="breadcrumb$ | async as breadcrumb">
    <div class="text-sm breadcrumbs">
      <ul>
        <li>
          <a href="/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>
          </a>
        </li>
        <li *ngIf="breadcrumb.vehicle">
          <a href="/vehicles/{{breadcrumb.vehicleId}}">{{breadcrumb.vehicle}}</a>
        </li>
        <li *ngIf="breadcrumb.compartment">
          <a href="/compartments/{{breadcrumb.compartmentId}}">{{breadcrumb.compartment}}</a>
        </li>
        <li *ngIf="breadcrumb.item">
          <a href="/items/{{breadcrumb.itemId}}">{{breadcrumb.item}}</a>
        </li>
      </ul>
    </div>
  </div>
  -->
  <router-outlet></router-outlet>
</div>
