<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="container mx-auto">
  <div class="flex justify-center mb-4">
    <ul class="steps">
      <li *ngFor="let step of steps; index as i" class="step"
          [ngClass]="{'step-primary': currentStep === i}"
      >{{step.step.title}}</li>
    </ul>
  </div>

  <div class="outer-container">
    <div class="button-container">
      <div class="left" *ngIf="currentStep !== 0"><button (click)="previousStep()" class="btn btn-outline">Tilbage</button></div>
      <div class="right" *ngIf="currentStep !== this.steps.length - 1"><button (click)="nextStep()" class="btn btn-outline btn-primary">Videre</button></div>
    </div>
  </div>
  <div class="content">
    <article class="prose max-w-none">
      <div [innerHTML]="getContent()"></div>
    </article>
  </div>
  <!-- only if 1 guide is necessary -->
  <div *ngIf="isNotNullOrUndefinedOrEmptyOrWhitespace(this.getCurrentStep().winContent) && !isNotNullOrUndefinedOrEmptyOrWhitespace(this.getCurrentStep().osxContent)" role="tablist" class="tabs tabs-lg tabs-lifted justify-center ">
    <input type="radio" name="platform-tab" role="tab" class="tab" aria-label="Trinvis vejledning" checked="checked" />
    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
      <article class="prose max-w-none">
        <div [innerHTML]="getWindowsContent()"></div>
      </article>
    </div>
  </div>

  <!-- if more than 1 guide is necessary -->
  <div *ngIf="isNotNullOrUndefinedOrEmptyOrWhitespace(this.getCurrentStep().winContent) && isNotNullOrUndefinedOrEmptyOrWhitespace(this.getCurrentStep().osxContent)" role="tablist" class="tabs tabs-lg tabs-lifted justify-center ">
    <input type="radio" name="platform-tab" role="tab" class="tab" aria-label="Windows" checked="checked" />
    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
      <article class="prose max-w-none">
        <div [innerHTML]="getWindowsContent()"></div>
      </article>
      <a *ngIf="downloads[currentStep] !== ''" href="{{downloads[currentStep]}}.exe" download="Wazuh.exe">
        <button class="btn"><i class="fa fa-download"></i> Download</button>
      </a>
    </div>

    <input type="radio" name="platform-tab" role="tab" class="tab" aria-label="MacOS"/>
    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
      <article class="prose max-w-none">
        <div [innerHTML]="getMacOSContent()"></div>
      </article>
      <a *ngIf="downloads[currentStep] !== ''" href="{{downloads[currentStep]}}.APP" download="Wazuh.APP">
        <button class="btn"><i class="fa fa-download"></i> Download</button>
      </a>
    </div>

    <!-- not sure if this is going to be used
    <input type="radio" name="platform-tab" role="tab" class="tab" aria-label="Linux" />
    <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6">
      <article class="prose max-w-none">
        <div [innerHTML]="getLinuxContent()"></div>
      </article>
    </div> -->
  </div>
  <div class="outer-container">
    <div class="button-container">
      <div class="left" *ngIf="currentStep !== 0"><button (click)="previousStep()" class="btn btn-outline">Tilbage</button></div>
      <div class="right" *ngIf="currentStep !== this.steps.length - 1"><button (click)="nextStep()" class="btn btn-outline btn-primary">Videre</button></div>
    </div>
  </div>
</div>
